<template>
  <v-list-item @click="$emit('selectPaymentBatch', paymentBatch)">
    <v-list-item-icon>
      <v-icon v-if="paymentBatch.state === 'open'">
        mdi-lock-open-variant
      </v-icon>
      <v-icon
        color="success"
        v-if="paymentBatch.state === 'closed'"
      >
        mdi-lock
      </v-icon>
      <v-icon
        color="info"
        v-if="paymentBatch.state === 'paused'"
      >
        mdi-bell-sleep
      </v-icon>
      <v-icon
        color="success"
        v-if="paymentBatch.state === 'pending'"
      >
        mdi-timer-sand
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ paymentBatch.title }}</v-list-item-title>
      <v-list-item-subtitle>{{ paymentBatch.description }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'PaymentBatchListItem',
    props: {
        paymentBatch: {
            type: Object,
            default: null
        }
    }
}
</script>